<template>
  <div class="form">
    <div class="wrapper illustration illustration_dots">
      <Backlink title="questionnaire" service="refMortgage" backlink="/refinance-mortgage/about" />
      <div class="content">
        <div class="content__container">
          <p class="promo promo_blocks2">{{ "rm_s4p13_title" | localize }}</p>

          <form @submit.prevent="submitHandler">
            <div v-for="(acc, k) in accounts" :key="k">
              <div class="promo__container" v-if="clientCount > 1">
                <p class="promo promo_blocks2">{{ "program" | localize }} {{ k + 1 }}</p>
                <button
                  type="button"
                  v-if="accounts.length > 1 && k > 1"
                  class="delete"
                  @click="deleteAccount(k)"
                ></button>
              </div>
              <div :class="'scroll_to_' + k"></div>

              <div class="form__group">
                <v-select
                  v-model.lazy="acc.program_id"
                  @change="check(k, 'program_id')"
                  :class="{ invalid: checks[k].program_id && !acc.program_id }"
                  item-text="name"
                  item-value="id"
                  :items="programs"
                  :label="'rm_s4p13_label_1' | localize"
                  :placeholder="'select' | localize"
                  :menu-props="{ offsetY: true }"
                  append-icon="icon-arrow-down"
                  outlined
                ></v-select>

                <v-text-field
                  v-model.lazy="acc.amount"
                  @input="check(k, 'amount')"
                  class="input_number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @keypress="onKeyPressHandler"
                  @keyup="onKeyUpArrayHandler(acc.amount, k, 'amount')"
                  :class="{ invalid: checks[k].amount && !acc.amount }"
                  :label="'rm_s4p13_label_2' | localize"
                  :placeholder="'rm_s4p13_placeholder_2' | localize"
                  append-icon="icon-₪"
                  outlined
                ></v-text-field>
              </div>

              <div class="form__group">
                <v-text-field
                  v-model.lazy="acc.months"
                  @input="check(k, 'months')"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  :class="{ invalid: checks[k].months && !acc.months }"
                  :label="'rm_s4p13_label_3' | localize"
                  :placeholder="'s4p11_placeholder_1' | localize"
                  outlined
                ></v-text-field>

                <v-text-field
                  v-model.lazy="acc.percent"
                  @input="check(k, 'percent')"
                  inputmode="numeric"
                  :class="{ invalid: checks[k].percent && !acc.percent }"
                  :label="'rm_s4p13_label_4' | localize"
                  :placeholder="'rm_s4p13_placeholder_1' | localize"
                  append-icon="icon-percent"
                  outlined
                ></v-text-field>
              </div>

              <div class="form__group">
                <v-select
                  v-model.lazy="acc.type"
                  @change="check(k, 'type')"
                  :class="{ invalid: checks[k].type && !acc.type }"
                  :items="getList('pay_select_', 3)"
                  item-text="name"
                  item-value="value"
                  :label="'pay_type' | localize"
                  :placeholder="'select' | localize"
                  :menu-props="{ offsetY: true }"
                  append-icon="icon-arrow-down"
                  outlined
                ></v-select>
              </div>
            </div>

            <div class="error__container">
              <p class="error" v-if="not_enough">{{ 'programs_not_enough' | localize }} {{ balance.toLocaleString() }} <span class="icon-₪"></span></p>
            </div>

            <div class="error__container">
              <p class="error" v-if="error">{{ error | localize }}</p>
            </div>

            <button
              type="button"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
              @click="submitHandler"
            >
              {{ "form_continue" | localize }}
            </button>

            <button
              type="button"
              class="button button__form button__form_empty"
              @click="addAccount(scheme)"
            >
              + {{ "add_program" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import { clientMixin } from '@/mixins/clientMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'

export default {
  name: 'Refinance_mortgage_programs',
  components: { Backlink },
  mixins: [clientMixin, commonMixin, numberMixin],
  data: () => ({
    programs: [],
    scheme: { program_id: null, amount: null, months: null, percent: null, type: null },
    backlink: '/',
    balance: 0,
    not_enough: false
  }),
  created () {
    const refMortgage = this.$cookies.get('refMortgage') || {}
    this.balance = parseInt(this.parseNumber(refMortgage.balance || 0))

    // Получатели и данные
    this.members = refMortgage.saved_clients ? refMortgage.saved_clients.length : 1
    this.clients = refMortgage.saved_clients || []
    this.accounts = refMortgage.programs || []
    if (this.members > 1) this.scheme.client = null

    this.prepareScheme(this.scheme)
    this.success = !this.checkError()
  },
  mounted () {
    this.axios
      .get('program').then((response) => {
        this.programs = response.data
      }).catch(() => {
        this.error = 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })

    this.accounts = this.changePrices(this.accounts, { amount: 0 })
  },
  methods: {
    prepareScheme (scheme) {
      if (this.accounts.length > 0) {
        this.clientCount = this.accounts.length
        this.checks = this.accounts.map((c) =>
          JSON.parse(JSON.stringify(scheme))
        )
      } else {
        this.clientCount = 2
        this.accounts = [JSON.parse(JSON.stringify(scheme)), JSON.parse(JSON.stringify(scheme))]
        this.checks = [JSON.parse(JSON.stringify(scheme)), JSON.parse(JSON.stringify(scheme))]
      }
    },
    checkSum () {
      const sumAmount = this.accounts.reduce((sum, ac) => sum + parseInt(this.parseNumber(ac.amount)), 0)
      this.not_enough = this.balance > sumAmount
    },
    changePercent (array, keys) {
      for (const [idx, object] of array.entries()) {
        for (const key in object) {
          if (key in keys && array[idx][key]) {
            array[idx][key] = parseFloat(array[idx][key])
          }
        }
      }
      return array
    },
    submitHandler () {
      const errorKey = this.checkError(true)
      if (errorKey !== false) {
        this.$scrollTo('.scroll_to_' + errorKey, 300, { offset: -150 })
        this.success = false
        return
      }

      this.checkSum()
      if (this.not_enough) {
        this.success = false
        return
      }

      this.success = true
      const refMortgage = this.$cookies.get('refMortgage') || {}
      refMortgage.programs = this.changePrices(this.accounts, { amount: 0 }, true)
      refMortgage.programs = this.accounts = this.changePercent(this.accounts, { percent: 0 })
      this.$cookies.set('refMortgage', refMortgage)
      this.saveClientData('service_old_program', '/refinance-mortgage/reg-payment', 'refMortgage')
    }
  }
}
</script>
